import * as React from 'react'

function SvgX(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      {...props}
    >
      <path
        d="M412 5100c-97-26-173-70-248-144-75-76-119-151-144-252-20-76-20-115-20-2144S0 492 20 416c25-101 69-176 144-252C240 89 315 45 416 20 492 0 531 0 2560 0s2068 0 2144 20c101 25 176 69 252 144 75 76 119 151 144 252 20 76 20 115 20 2144s0 2068-20 2144c-26 103-69 177-149 256-79 77-142 114-247 140-76 20-117 20-2148 19-2010 0-2073-1-2144-19zm1890-1407c244-356 448-648 454-649 5-1 252 281 549 627s549 639 561 650c19 18 33 19 169 17l148-3-628-730c-345-401-635-739-645-750-18-20-5-40 686-1045 387-564 704-1026 704-1027 0-2-233-3-518-3h-518l-473 690c-261 379-477 688-480 685-4-2-272-312-596-689l-590-686H818l40 48c22 26 328 382 680 790l640 744-586 851c-321 469-627 914-679 990l-94 137h1039l444-647z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M1252 4088c44-67 2142-3065 2152-3076 9-9 67-12 244-10l231 3-1083 1550-1084 1550-237 3-238 2 15-22z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  )
}

export default SvgX
