import React from 'react'

import SvgFacebook from './svgFacebook'
import SvgX from './svgX'
import SvgWhatsapp from './svgWhatsapp'

const Share = ({title, label, isVertical, redirect}) => {
  const url = redirect
  const whatsappShareLink = `https://wa.me/?text=${encodeURI(title)}%20${url}`
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURI(
    title,
  )} ${url}`
  const linkedInShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const workplaceShareLink = `https://work.facebook.com/sharer.php?display=page&u=${url}`

  const renderSocialShareButtons = () => (
    <>
      <a
        href={whatsappShareLink}
        className="shareButton"
        rel="noreferrer"
        target="_blank"
      >
        <SvgWhatsapp />
      </a>
      <a
        href={twitterShareLink}
        className="shareButton"
        rel="noreferrer"
        target="_blank"
      >
        <SvgX />
      </a>

      <a
        href={facebookShareLink}
        className="shareButton"
        rel="noreferrer"
        target="_blank"
      >
        <SvgFacebook />
      </a>
    </>
  )

  const renderHorizontal = () => (
    <div className="share-container">
      {label && label}
      <div className="wrap">
        <div className="links">
          <div className="logos">{renderSocialShareButtons()}</div>
        </div>
      </div>
    </div>
  )

  const renderVertical = () => (
    <div className="verticalContainer">
      {label}
      <div className="links">
        <div className="verticalLogos">{renderSocialShareButtons()}</div>
      </div>
    </div>
  )

  return isVertical ? renderVertical() : renderHorizontal()
}

export default Share
