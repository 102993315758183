import * as React from 'react'

function SvgWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M412 5100c-97-26-173-70-248-144-75-76-119-151-144-252-20-76-20-115-20-2144S0 492 20 416c25-101 69-176 144-252C240 89 315 45 416 20 492 0 531 0 2560 0s2068 0 2144 20c101 25 176 69 252 144 75 76 119 151 144 252 20 76 20 115 20 2144s0 2068-20 2144c-26 103-69 177-149 256-79 77-142 114-247 140-76 20-117 20-2148 19-2010 0-2073-1-2144-19zm2466-1039c299-65 536-195 752-411 215-213 345-454 412-757 30-134 32-433 5-568-125-616-582-1076-1189-1197-160-32-417-31-568 1-132 28-231 61-342 113l-86 40-391-102c-216-56-393-101-396-99-2 2 44 175 101 384l105 380-50 105c-61 130-83 190-109 305-79 340-40 679 112 990 79 160 164 281 281 400 227 230 515 377 840 430 126 20 398 13 523-14z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M2415 3829c-185-25-402-111-553-218-532-378-683-1095-350-1655l41-70-62-224c-33-124-60-226-58-228 1-1 105 25 232 58l229 60 96-50c194-102 365-144 585-145 215-1 384 40 575 139 441 227 712 723 662 1212-31 306-145 548-361 763-189 189-401 302-656 349-84 15-297 21-380 9zm-236-567c9-12 47-97 85-188l69-166-21-42c-11-22-41-65-66-94-25-30-46-59-46-67 0-7 23-48 51-91 63-97 190-224 284-286 95-63 208-112 237-104 13 3 56 47 96 96 59 72 79 90 100 90 28 0 313-131 345-158 24-21 22-107-3-176-26-67-78-116-169-160-62-29-73-31-176-31-99 1-120 4-205 34-196 69-317 137-462 258-216 180-431 480-469 654-17 79-8 202 20 264 34 77 98 154 145 176 58 28 161 22 185-9z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  )
}

export default SvgWhatsapp
